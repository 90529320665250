import {
  getDownloadURL as getDownloadURLFirebase,
  getStorage,
  ref as storageRef,
  StorageReference,
  uploadBytesResumable,
  UploadTask,
} from "@firebase/storage";
import { useFirebaseStorage } from "vuefire";
import { SupportedFileMimeType } from "~/types/enums/SupportedFileMimeType.enum";

export const useFileUploader = () => {
  const storage = useFirebaseStorage();
  const altStorage = getStorage();

  const createMediaObject = async (
    file: File | CustomFile,
    path?: string,
    includeTimestamp: boolean = true
  ): Promise<MediaObject> => {
    const uid = useCurrentUID();

    if (!path) {
      path = `/user-uploads/${uid}`;
    }

    if (includeTimestamp != true) {
      path += `/${file.name}`;
    } else {
      path += `/[${new Date().getTime()}]_${file.name}`;
    }

    const fileRef = storageRef(storage, path);

    return {
      id: crypto.randomUUID(),
      name: fileRef.name,
      originalFileName: file.name,
      fullPath: fileRef.fullPath,
      size: file.size,
      bucket: fileRef.bucket,
      contentType: file.type,
      mediaType: file.type,
      mediaHref: "",
      mimeType: file.type as SupportedFileMimeType,
    };
  };

  const uploadFile = (
    file: File | Uint8Array | ArrayBuffer | Blob,
    mediaObject: MediaObject
  ): UploadTask => {
    const fileRef = storageRef(storage, mediaObject.fullPath);
    return uploadBytesResumable(fileRef, file);
  };

  const uploadFileFromBuffer = (
    data: ArrayBuffer,
    mediaObject: MediaObject
  ): UploadTask => {
    const fileRef = storageRef(storage, mediaObject.fullPath);

    return uploadBytesResumable(fileRef, data);
  };

  const uploadFileAndCreateMediaObject = async (
    file: File,
    path?: string
  ): Promise<UploadTask> => {
    const mediaObject = await createMediaObject(file, path);
    const uploadTask = uploadFile(file, mediaObject);
    return uploadTask;
  };

  const uploadFileFromBufferAndCreateMediaObject = async (
    file: CustomFile,
    path?: string
  ): Promise<UploadTask> => {
    const mediaObject = await createMediaObject(file, path);
    const uploadTask = uploadFileFromBuffer(file.data as Buffer, mediaObject);
    return uploadTask;
  };

  const getCleanFileName = (fileName: string): string => {
    const firstExpression = /\[(.*[0-9])\]_/;
    const name = fileName.replace(firstExpression, "");
    const lastIndex = name.lastIndexOf(".");

    return name.substring(0, lastIndex);
  };

  const getDownloadURL = (fileRef: StorageReference): Promise<string> => {
    return getDownloadURLFirebase(fileRef);
  };

  return {
    createMediaObject,
    uploadFile,
    uploadFileFromBuffer,
    uploadFileAndCreateMediaObject,
    uploadFileFromBufferAndCreateMediaObject,
    getDownloadURL,
    getCleanFileName,
  };
};
